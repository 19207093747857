<template>

   <div>
      <div class="card-body">
         <p class="login-box-msg">Informe suas credenciais para criar a conta</p>

         <form>
            <div class="input-group mb-3">
               <input type="text" class="form-control" placeholder="Nome" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-user"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="text" class="form-control" placeholder="Email" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-envelope"></span>
                  </div>
               </div>
            </div>
            <div class="input-group mb-3">
               <input type="password" class="form-control" placeholder="Senha" required>
               <div class="input-group-append">
                  <div class="input-group-text">
                     <span class="fas fa-lock"></span>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- /.col -->
               <div class="col-12">
                  <button type="submit" class="btn btn-outline-secondary btn-block"><b>Cadastre-se</b></button>
               </div>
               <!-- /.col -->
            </div>
         </form>

         <p class="mb-1 mt-4">
            <router-link :to="{name: 'login'}" class="text-gray-dark">Já possui uma conta, clique aqui.</router-link>
         </p>
      </div>
      <!-- /.card-body -->
   </div>

</template>


<script>
    export default {
        name: 'Register'
    }
</script>
